<template>
  <!-- 短信模板 -->
  <div class="emailAll">
    <!-- 首页和新建短信页切换 -->
    <component
      :is="diaComponents[stepIndex].component"
      ref="iscomponent"
      :editOrNew="editOrNewTem"
      :editTemInfo="editInfo"
      @openNewEmail="openNewEmail(arguments)"
      @toTempleteShow="toTempleteShow"
    ></component>
  </div>
</template>
<script>
import firstChild from "@/views/systemSettings/components/communicationTemplate/components/messageComponents/emailTemp.vue";
import secondChild from "@/views/systemSettings/components/communicationTemplate/components/messageComponents/createEmailTemp.vue";
export default {
  components: {
    firstChild,
    secondChild,
  },
  data() {
    return {
      // 默认显示邮件模板界面
      stepIndex: 0,
      // 编辑还是新建模板
      editOrNewTem: "",
      editInfo: {},
      diaComponents: [
        {
          title: "短信模板",
          component: "firstChild",
        },
        {
          title: "新建短信模板",
          component: "secondChild",
        },
      ],
    };
  },
  methods: {
    // 点击编辑、新建邮件模板
    openNewEmail(val) {
      // 判断点击有没有值
      if (val[1]) {
        this.editInfo = val[1];
      }
      // 传值编辑还是新建
      this.editOrNewTem = val;
      this.stepIndex = 1;
    },
    // 返回首页
    toTempleteShow() {
      this.stepIndex = 0;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>