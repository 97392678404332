<template>
  <div>
    <!-- 选择模板类型和新建模板页切换 -->
    <component
      :is="diaComponents[stepIndex].component"
      :judgeType="textOrHtml"
      :headerTemp="templeteName"
      :editTemInfo="editTemInfoProp"
      @selectTemType="selectTemType"
      @cancelNewTemplete="cancelNewTemplete"
    ></component>
  </div>
</template>
<script>
import secondChild from "@/views/systemSettings/components/communicationTemplate/components/messageComponents/Templete/newTemplete.vue";
export default {
  components: {
    secondChild,
  },
  props: {
    editOrNew: {
      type: String,
      default: "",
    },
    editTemInfo: {
      type: Object,
      default: ()=> {},
    },
  },
  data() {
    return {
      editTemInfoProp: this.editTemInfo,
      stepIndex: null,
      // 编辑还是新建模板的title
      templeteName: "",
      diaComponents: [
        {
          title: "新建、编辑模板",
          component: "secondChild",
        },
      ],
      textOrHtml: "",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    // 初始化
    init() {
      if (this.editOrNew[0] === "new") {
        this.templeteName = this.editOrNew[0];
        this.textOrHtml = "1";
        this.stepIndex = 0;
      } else if (this.editOrNew[0] === "edit") {
        this.templeteName = this.editOrNew[0];
        this.TempleteId = this.editOrNew[1].id;
        if (this.editOrNew[1].isText === "true") {
          this.textOrHtml = "1";
        } else if (this.editOrNew[1].isText === "false") {
          this.textOrHtml = "2";
        }
        this.stepIndex = 0;
      }
    },
    // 点击下一步
    selectTemType(val) {
      this.textOrHtml = val;
      this.templeteName = this.editOrNew[0];
      this.stepIndex = 1;
    },
    // 点击取消
    cancelNewTemplete() {
      this.$emit("toTempleteShow");
    },
  },
};
</script>
<style lang="scss" scoped>
</style>