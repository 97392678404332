<template>
  <!-- 短信模板 -->
  <div class="email">
    <p class="eTitle">{{ $t("label.smstemplate") }}</p>
    <p class="eContent">
      {{ $t("label.smstemplate.tip") }}
    </p>
    <div class="main">
      <!-- 文件夹 -->
      <div class="mLeft">
        <p class="mLeftTitle">{{ $t("label.smstemplate") }}</p>
        <div class="mLeftBox">
          <div class="mLeftBoxTitle">{{ $t("label.smstemplate") }}</div>
          <div class="mLeftBoxallTem">
            <a
              href="javascript:void(0)"
              :class="{
                blueColor: 'all' == currentClick,
                blackColor: 'all' != currentClick,
              }"
              @click="selectAllTem"
              >{{ $t("vue_label_SMS_alltemplates") }}</a
            >
          </div>
          <div class="mLeftBoxTitle">{{ $t("report.label.folder") }}</div>
          <!-- 预置文件夹 -->
          <div class="fixFolder">
            <li class="fixLi" v-for="(item, index) in fixFolder" :key="index">
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.name"
                placement="right-start"
              >
                <div class="overflowDiv">
                  <a
                    :class="{
                      blueColor: item.id == currentClick,
                      blackColor: item.id != currentClick,
                    }"
                    href="javascript:void(0)"
                    @click="selectTem(item.id)"
                    >{{ item.name }}</a
                  >
                </div>
              </el-tooltip>
            </li>
          </div>
          <!-- 可编辑文件夹 -->
          <div class="nofixFolder">
            <li class="fixLi" v-for="(item, index) in nofixFolder" :key="index">
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.name"
                placement="right-start"
              >
                <div class="overflowDiv">
                  <a
                    :class="{
                      blueColor: item.id == currentClick,
                      blackColor: item.id != currentClick,
                    }"
                    href="javascript:void(0)"
                    @click="selectTem(item.id)"
                    >{{ item.name }}</a
                  >
                </div>
              </el-tooltip>
              <div class="xialaPosi">
                <el-popover
                  placement="bottom-start"
                  :visible-arrow="false"
                  width="120"
                  popper-class="btnPopOver"
                  trigger="hover"
                  @show="popOver(item)"
                  @hide="popOut(item)"
                >
                  <svg class="icon" aria-hidden="true" slot="reference">
                    <use
                      :href="
                        item.popoverout ? '#icon-dropDown1' : '#icon-xiala'
                      "
                    ></use>
                  </svg>
                  <!-- 操作弹框 -->
                  <div class="btnLi">
                    <li @click="editFolder('edit', item, index)">
                      <a href="javascript:void(0)">{{ $t("label.modify") }}</a>
                    </li>
                    <li @click="deleteFolder(item, index)">
                      <a href="javascript:void(0)">{{ $t("label.delete") }}</a>
                    </li>
                  </div>
                </el-popover>
              </div>
            </li>
          </div>
        </div>
        <el-dialog
          :title="$t('label.emailmerge.list.deletefolder')"
          :visible.sync="deleteFolderVisible"
          width="24%"
          top="29vh"
        >
          <div class="spantip">
            {{ $t("front-kefu-moudle-v1-yesorno-delete-folder") }}
          </div>
          <br />
          <div class="spanwarn">{{ $t("vue_label_norm_undone") }}</div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="deleteFolderVisible = false">{{
              $t("label.tabpage.cancel")
            }}</el-button>
            <el-button type="primary" @click="deleteFolConfirm">{{
              $t("label.tabpage.ok")
            }}</el-button>
          </span>
        </el-dialog>
      </div>
      <div class="mRight">
        <div class="mRightTop">
          <div class="btnTop">
            <el-button plain @click="newEmailTemp('new')">{{
              $t("label.smstemplate.new")
            }}</el-button>
            <el-button plain @click="editFolder('new')">{{
              $t("label.file.folder.create")
            }}</el-button>
            <el-button plain @click="batchDelete">{{
              $t("label.batchDelete")
            }}</el-button>
          </div>
        </div>
        <div class="tablePannel">
          <el-table
            v-loading="tableLoading"
            :empty-text="$t('label.weixin.nomore')"
            ref="templeteTable"
            v-loadmore="loadMore"
            height="361"
            :data="templateList"
            :row-style="{ height: 39 + 'px' }"
            :cell-style="{ padding: 0 + 'px' }"
            border
            :row-key="getRowKey"
          >
            <el-table-column type="selection" :reserve-selection="true">
            </el-table-column>
            <el-table-column>
              <template slot="header">
                <span>{{ $t("label.emailtocloudcc.filed.action") }}</span>
              </template>
              <template slot-scope="scope">
                <div class="atags">
                  <a
                    href="javascript:void(0)"
                    @click="newEmailTemp('edit', scope.row, scope.$index)"
                  >
                    <span>{{ $t("label.modify") }}</span></a
                  >
                  |
                  <a
                    href="javascript:void(0)"
                    @click="deleteTemplete(scope.row, scope.$index)"
                    ><span>{{ $t("label.delete") }}</span></a
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :prop="item.name"
              v-for="(item, index) in headList"
              :key="index"
              :label="item.fieldLabel"
              min-width="106"
              :show-overflow-tooltip="item.id === '006' ? false : true"
            >
              <template slot-scope="scope">
                <div v-if="item.id == '003'">
                  <span v-if="scope.row.isText == 'true'">{{
                    $t("label.text")
                  }}</span>
                  <span v-else-if="scope.row.isText == 'false'">HTML</span>
                </div>
                <div v-else-if="item.id == '006'">
                  <el-checkbox
                    v-model="isEnableStatus"
                    v-if="scope.row.isEnable == 'true'"
                    disabled
                  ></el-checkbox>
                  <el-checkbox
                    v-model="DisableStatus"
                    v-else-if="scope.row.isEnable == 'false'"
                    disabled
                  ></el-checkbox>
                </div>
                <div v-else-if="item.id == '007'">
                  {{ scope.row[item.name] | formatData }}
                </div>
                <div v-else>{{ scope.row[item.name] }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="newFolderDia">
          <editOrNewFo
            ref="editNewFo"
            :newFolderTo="newFolderTo"
            :header="folderHeader"
            :folderId="newFolderId"
            @newFolderCancel="newFolderCancel"
          ></editOrNewFo>
        </div>
        <el-dialog
          :title="$t('front-kefu-moudle-v1-delete-templete')"
          :visible.sync="deleteTempleteVisible"
          width="24%"
          top="29vh"
        >
          <div class="spantip">
            {{ $t("front-kefu-moudle-v1-yesorno-delete-templete") }}
          </div>
          <br />
          <div class="spanwarn">{{ $t("vue_label_norm_undone") }}</div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="deleteTempleteVisible = false">{{
              $t("label.tabpage.cancel")
            }}</el-button>
            <el-button type="primary" @click="deleteTemConfirm">{{
              $t("label.tabpage.ok")
            }}</el-button>
          </span>
        </el-dialog>
        <el-dialog
          :title="$t('front-kefu-moudle-v1-delete-templete')"
          :visible.sync="batchdeleteTempleteVisible"
          width="24%"
          top="29vh"
        >
          <div class="spantip">
            {{ $t("front-kefu-moudle-v1-yesornoBatch-delete-templete") }}
          </div>
          <br />
          <div class="spanwarn">{{ $t("vue_label_norm_undone") }}</div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="batchdeleteTempleteVisible = false">{{
              $t("label.tabpage.cancel")
            }}</el-button>
            <el-button type="primary" @click="batchDeleteConfirm">{{
              $t("label.tabpage.ok")
            }}</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import editOrNewFo from "@/views/systemSettings/components/communicationTemplate/components/messageComponents/Folder/editOrNewFo.vue";
import * as emailTemplate from "../../../../systemSettingsApi";

export default {
  components: {
    editOrNewFo,
  },
  directives: {
    loadmore: {
      bind(el, binding) {
        const selectWrap = el.querySelector(".el-table__body-wrapper");
        selectWrap.addEventListener("scroll", function () {
          let sign = 10;
          const scrollDistance =
            this.scrollHeight - this.scrollTop - this.clientHeight;
          if (scrollDistance <= sign) {
            binding.value();
          }
        });
      },
    },
  },

  data() {
    return {
      timer: null,
      // 初始页数
      initNum: 1,
      // 每次表格返回的总页数
      totalPage: 0,
      // 查找表格数据的文件夹id
      needFolderId: "",
      // 预置文件夹
      fixFolder: [],
      // 可编辑文件夹
      nofixFolder: [],
      // 可供使用
      isEnableStatus: true,
      // 不可使用
      DisableStatus: false,
      // 被删除的模板id
      deleteTempId: "",
      // 删除文件夹弹框
      deleteFolderVisible: false,
      // 删除模板弹框
      deleteTempleteVisible: false,
      // 批量删除模板弹框
      batchdeleteTempleteVisible: false,
      // 新建编辑弹框
      newFolderTo: false,
      // 编辑id
      newFolderId: "",
      // 会话框标题
      folderHeader: this.$i18n.t("label.file.folder.create"),
      // 模板列表
      templateList: [],
      // 表格loading
      tableLoading: true,
      currentClick: "all",
      // 模板列表表头
      headList: [
        {
          id: "001",
          fieldLabel: this.$i18n.t("label.emailobject.template.name"),
          name: "name",
        },
        {
          id: "002",
          fieldLabel: this.$i18n.t("label.uniquename"),
          name: "apiCode",
        },
        {
          id: "003",
          fieldLabel: this.$i18n.t("label.emailtemplate.type"),
          name: "isText",
        },

        {
          id: "005",
          fieldLabel: this.$i18n.t("label.folder"),
          name: "foldername",
        },
        {
          id: "006",
          fieldLabel: this.$i18n.t("label.istouse"),
          name: "isEnable",
        },
        {
          id: "007",
          fieldLabel: this.$i18n.t("label.lastmodifydate"),
          name: "lastmodifydate",
        },
      ],
      headListAll: [
        {
          id: "001",
          fieldLabel: this.$i18n.t("label.emailobject.template.name"),
          name: "name",
        },
        {
          id: "002",
          fieldLabel: this.$i18n.t("label.uniquename"),
          name: "apiCode",
        },
        {
          id: "003",
          fieldLabel: this.$i18n.t("label.emailtemplate.type"),
          name: "isText",
        },
        {
          id: "005",
          fieldLabel: this.$i18n.t("label.folder"),
          name: "foldername",
        },
        {
          id: "006",
          fieldLabel: this.$i18n.t("label.istouse"),
          name: "isEnable",
        },
        {
          id: "007",
          fieldLabel: this.$i18n.t("label.lastmodifydate"),
          name: "lastmodifydate",
        },
      ],
      headListSingle: [
        {
          id: "001",
          fieldLabel: this.$i18n.t("label.emailobject.template.name"),
          name: "name",
        },
        {
          id: "002",
          fieldLabel: this.$i18n.t("label.uniquename"),
          name: "apiCode",
        },
        {
          id: "003",
          fieldLabel: this.$i18n.t("label.emailtemplate.type"),
          name: "isText",
        },
        {
          id: "004",
          fieldLabel: this.$i18n.t("label.emailtocloudcc.filed.type"),
          name: "etype",
        },
        {
          id: "006",
          fieldLabel: this.$i18n.t("label.istouse"),
          name: "isEnable",
        },
        {
          id: "007",
          fieldLabel: this.$i18n.t("label.lastmodifydate"),
          name: "lastmodifydate",
        },
      ],
    };
  },
  mounted() {
    this.init();
  },
  filters: {
    formatData(val) {
      if (val) {
        var date = new Date(val); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + "-";
        var M =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-";
        var D =
          (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
        var h =
          (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
          ":";
        var m =
          (date.getMinutes() < 10
            ? "0" + date.getMinutes()
            : date.getMinutes()) + ":";
        var s =
          date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
        return Y + M + D + h + m + s;
      }
    },
  },
  methods: {
    // 初始化
    init() {
      this.refreshTemp();
    },

    // 刷新模板和文件夹
    refreshTemp() {
      this.queryTemplateFolder();
      this.selectAllTem();
    },

    // 节流事件
    throttleFn(method, delay, duration) {
      var that = this;
      var timer = this.timer;
      var begin = new Date().getTime();
      return function () {
        var context = that;
        var args = arguments;
        var current = new Date().getTime();
        clearTimeout(timer);
        if (current - begin >= duration) {
          method.apply(context, args);
          begin = current;
        } else {
          that.timer = setTimeout(function () {
            method.apply(context, args);
          }, delay);
        }
      };
    },

    // 表格滚动加载事件
    loadMore() {
      this.throttleFn(this.loadmoreFn, 500, 1000)();
    },
    
    loadmoreFn() {
      this.tableLoading = true;
      if (this.needFolderId == "") {
        if (this.initNum < this.totalPage) {
          this.initNum = this.initNum + 1;
          let params = {
            etype: "SMS",
            pageNum: this.initNum,
            pageSize: 10,
            isQueryAll: "all",
            backgroundSetting: "true",
          };
          emailTemplate.queryTemplateByFolder(params).then((res) => {
            this.templateList = this.templateList.concat(res.data.templateList);
            this.tableLoading = false;
          });
        } else {
          this.tableLoading = false;
        }
      } else {
        if (this.initNum < this.totalPage) {
          this.initNum = this.initNum + 1;
          let params = {
            etype: "SMS",
            pageNum: this.initNum,
            pageSize: 10,
            templateFolderId: this.needFolderId,
            backgroundSetting: "true",
          };
          emailTemplate.queryTemplateByFolder(params).then((res) => {
            this.templateList = this.templateList.concat(res.data.templateList);
            this.tableLoading = false;
          });
        } else {
          this.tableLoading = false;
        }
      }
      this.needFolderId = "";
    },
    // 查询短信模板所有文件夹
    queryTemplateFolder() {
      let params = {
        foldertype: "message",
        backgroundSetting: true,
        purview:""
      };
      emailTemplate.queryTemplateFolder(params).then((res) => {
        this.fixFolder = [];
        let folderGyArr = res.data.filter((item) => {
          return item.id === "baf20211018template3";
        });
        let folderZyArr = res.data.filter((item) => {
          return item.id === "baf20211018template4";
        });
        this.fixFolder = this.fixFolder.concat(folderGyArr, folderZyArr);
        this.nofixFolder = res.data.filter((item) => {
          return (
            item.id !== "baf20211018template3" &&
            item.id !== "baf20211018template4"
          );
        });
        this.nofixFolder.forEach((item) => {
          this.$set(item, "popoverout", false);
        });
      });
    },

    // 查询所有模板
    selectAllTem() {
      this.currentClick = "all";
      this.tableLoading = true;
      this.headList = this.headListAll;
      this.$refs.templeteTable.clearSelection();
      this.initNum = 1;
      this.$nextTick(() => {
        this.$refs.templeteTable.bodyWrapper.scrollTop = 0;
      });
      let params = {
        etype: "SMS",
        pageNum: 1,
        pageSize: 10,
        isQueryAll: "all",
        backgroundSetting: "true",
      };
      emailTemplate.queryTemplateByFolder(params).then((res) => {
        if (res.result) {
          this.templateList = res.data.templateList;
          this.totalPage = res.data.totalPage;
          this.tableLoading = false;
        }
      });
    },
    // 查询文件夹内模板
    selectTem(temId) {
      this.currentClick = temId;
      this.tableLoading = true;
      this.headList = this.headListSingle;
      this.$refs.templeteTable.clearSelection();
      this.initNum = 1;
      this.$nextTick(() => {
        this.$refs.templeteTable.bodyWrapper.scrollTop = 0;
      });
      this.needFolderId = temId;
      let params = {
        etype: "SMS",
        templateFolderId: temId,
        pageNum: 1,
        pageSize: 10,
        backgroundSetting: "true",
      };
      emailTemplate.queryTemplateByFolder(params).then((res) => {
        if (res.result) {
          this.templateList = res.data.templateList;
          this.totalPage = res.data.totalPage;
          this.tableLoading = false;
        }
      });
    },

    // 点击编辑、新建文件夹
    editFolder(type, item) {
      if (type === "new") {
        this.newFolderTo = true;
        this.folderHeader = this.$i18n.t("label.file.folder.create");
        this.newFolderId = null;
      } else if (type === "edit") {
        this.newFolderTo = true;
        this.folderHeader = this.$i18n.t("label.file.folder.edit");
        this.newFolderId = item.id;
      }
    },

    // 关闭新建文件夹弹框
    newFolderCancel() {
      this.newFolderTo = false;
      this.refreshTemp();
    },

    // 鼠标落于下拉标
    popOver(item) {
      setTimeout(function () {
        item.popoverout = true;
      }, 50);
    },

    // 鼠标离开下拉标
    popOut(item) {
      setTimeout(function () {
        item.popoverout = false;
      }, 50);
    },

    // 点击删除文件夹
    deleteFolder(item) {
      this.deleteFolderVisible = true;
      this.newFolderId = item.id;
    },

    // 删除文件夹确认
    deleteFolConfirm() {
      let params = {
        idsForDelete: this.newFolderId,
      };
      emailTemplate.deleteTemplateFolder(params).then((res) => {
        if (res.result) {
          this.$message({
            message: this.$i18n.t("label.tabpage.delsuccessz"),
            type: "success",
          });
          this.deleteFolderVisible = false;
          this.refreshTemp();
        }
      });
    },

    // 点击删除模板
    deleteTemplete(item) {
      this.deleteTempleteVisible = true;
      this.deleteTempId = item.id;
    },

    // 删除模板确认
    deleteTemConfirm() {
      let params = {
        idsForDelete: this.deleteTempId,
      };
      emailTemplate.deleteTemplate(params).then((res) => {
        if (res.result) {
          this.$message({
            message: this.$i18n.t("label.tabpage.delsuccessz"),
            type: "success",
          });
          this.deleteTempleteVisible = false;
          this.refreshTemp();
        }
      });
    },

    // 编辑、新建短信模板
    newEmailTemp(type, item) {
      this.$emit("openNewEmail", type, item);
    },

    // 返回唯一值
    getRowKey(row) {
      return row.id;
    },

    // 批量删除
    batchDelete() {
      if (this.$refs.templeteTable.selection.length > 0) {
        this.batchdeleteTempleteVisible = true;
      } else {
        this.$message({
          message: this.$i18n.t("message.choosedelete"),
          type: "warning",
        });
      }
    },
    // 批量删除确认
    batchDeleteConfirm() {
      let selectObj = [];
      this.$refs.templeteTable.selection.forEach((item) => {
        selectObj.push(item.id);
      });
      let params = {
        idsForDelete: selectObj.join(","),
      };
      emailTemplate.deleteTemplate(params).then((res) => {
        if (res.result) {
          this.$message({
            message: this.$i18n.t("label.tabpage.delsuccessz"),
            type: "success",
          });
          this.batchdeleteTempleteVisible = false;
          this.refreshTemp();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.blueColor {
  color: #006dcc;
}
.blackColor {
  color: #080707;
}
.email {
  padding: 16px 10px 0 10px;
  .eTitle {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
  }
  .eContent {
    font-size: 14px;
    color: #333333;
    max-width: 808px;
    margin-top: 10px;
  }
}
.main {
  margin-top: 16px;
  height: 431px;
  background: #f8f8f8;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
  display: flex;
  flex-flow: row;
  .mLeft {
    width: 194px;
    padding: 20px 0 0 10px;
    .mLeftTitle {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
    .mLeftBox {
      margin-top: 20px;
      height: 361px;
      font-size: 14px;
      background: #ffffff;
      border: 1px solid #dcdcdc;
      .mLeftBoxTitle {
        height: 40px;
        font-weight: bold;
        color: #333333;
        background: #f0f0f0;
        padding-left: 10px;
        display: flex;
        align-items: center;
      }
      .mLeftBoxallTem {
        height: 40px;
        padding-left: 10px;
        display: flex;
        align-items: center;
      }
      .fixFolder {
        height: 68px;
        border-bottom: 1px #dedcda solid;
        .fixLi {
          height: 34px;
          line-height: 34px;
          padding-left: 10px;
          display: flex;
          justify-content: space-between;
          .xialaPosi {
            margin-right: 10px;
          }
        }
      }
      .nofixFolder {
        max-height: 172px;
        overflow-y: auto;
        .fixLi {
          height: 34px;
          line-height: 34px;
          padding-left: 10px;
          display: flex;
          justify-content: space-between;
          .overflowDiv {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .xialaPosi {
            margin-right: 10px;
          }
        }
      }
    }
  }
  .mRight {
    margin-left: 10px;
    flex: 1;
    .mRightTop {
      height: 61px;
      display: flex;
      align-items: center;
      .btnTop {
        height: 30px;
        margin-left: 150px;
        ::v-deep .el-button {
          height: 30px;
          font-size: 14px;
          line-height: 0;
        }
      }
    }
    .tablePannel {
      ::v-deep .el-table--border th,
      .el-table__fixed-right-patch {
        background-color: #f0f0f0;
        border: 1px solid #dcdcdc;
        color: #333333;
      }
      ::v-deep .el-table--border td {
        border-right: none;
      }
      .atags a {
        color: #006dcc;
      }
      ::v-deep .el-table .cell div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.btnLi li {
  height: 32px;
  line-height: 32px;
  font-size: 12px;
  color: #080707;
  padding-left: 10px;
  a {
    color: #333333;
  }
}
.btnLi li:hover {
  background: #f3f6f9;
  a {
    color: #006dcc;
  }
}
.spantip {
  font-size: 14px;
  color: #333333;
}
.spanwarn {
  font-size: 14px;
  color: #ff4141;
}
</style>
<style lang="scss">
.el-popover.btnPopOver {
  padding: 0;
}
</style>
